.nav-link {
  color: white;
  font-style: normal;
}

.box-shadow {
  box-shadow: 0px 0px 20px 0px black;
}

.main-header {
  margin-bottom: 0;
}

.header-font {
  font-family: 'Avenir', 'Arial', sans-serif;
}

.portfolio-description {
  max-width: 800px;
}

.main-space {
  height: 30px;
}

.second-space {
  height: 200px
}

.email-me {
  font-size: 18pt;
}

.image-width {
  max-width: 100%;
}

.portfolio-header {
  padding-bottom: 1rem;
  border-bottom: 2px solid rgb(65, 124, 209);
  margin-bottom: 1rem;
}

.icon-column {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.skill-icon {
  width: 100px;
  height: 100px;
}

.contact-icon {
  width: 29px;
  height: 29px;
}

.envelope-mini-icon {
  color: black;
}

.location-text {
  margin-bottom: 0.4rem;
  margin-top: 0.3rem;
}

.medium-icon {
  width: 50px;
  height: 50px;
}
